import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import { Page } from '../styles/page'
import { options } from '../utils/pages'
import { GTTablet } from '../utils/responsive'

const Pages = [
  { code: 'start_page', url: '/', key: 1 },
  { code: 'about', url: '/about', key: 2 },
  { code: 'organisation', url: '/organisation', key: 3 },
]

const Activities = ({
  intl,
  data: {
    allContentfulPage: { edges },
  },
}) => {
  const document = edges[0].node[intl.locale]['raw']

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: 'activities' })} />
      <Page>{documentToReactComponents(JSON.parse(document), options)} </Page>
      <GTTablet>
        <Menu pages={Pages} />
      </GTTablet>
    </Layout>
  )
}

export const activitiesPageQuery = graphql`
  query {
    allContentfulPage(filter: { name: { eq: "activities" } }) {
      edges {
        node {
          name
          en {
            raw
          }
          ru {
            raw
          }
          nl {
            raw
          }
        }
      }
    }
  }
`

export default injectIntl(Activities)
